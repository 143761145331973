import Vue from 'vue'
import App from './App.vue'
import VueMatomo from 'vue-matomo'

import vuetify from './plugins/vuetify'

Vue.config.productionTip = false

Vue.use(VueMatomo, {
  // Configure your matomo server and site by providing
  host: 'https://stats.olex.me',
  siteId: 4,
  disableCookies: true,
  trackInitialView: true,
  enableHeartBeatTimer: true,
})

new Vue({
  vuetify,
  render: h => h(App)
}).$mount('#app')

